/**
*  UI-REFACTORED
*    FROM --> ./legacy-web/src/main/js/apps/common/js/jquery/aacom/plugins/aaCache.js
*    TO   --> ./webapps/shared-web/src/main/assets/js/plugins/aa-cache.js
**/

/**
 * Cache for DOM elements
 *  example:    cache.get('#elemId').show();
 *              cache.remove('#elemId');
 *
 * Note: DO NOT USE for dynamic object
 */
var aaCache = (function($j) {
    var cache = {};

    /**
     * Retrieves DOM element and caches it, returns null if not found.
     * @param {string} selector for DOM element, e.g. id, css class, element tag ...
     * @return {object} jQuery DOM element(s) or null
     */
    function _get(key) {
        if (!cache[key]) {
            cache[key] = $j(key);
        }

        return cache[key];
    }

    /**
     * Remove cached element
     * @param {string} key of cached DOM element
     */
    function _remove(key) {
        if (cache.hasOwnProperty(key)) {
            return (delete cache[key]);
        }

        return true;
    }

    /**
     * Clear cache
     */
    function _clear() {
        cache = {};

        return true;
    }

    return {
        get : _get,
        remove: _remove,
        clear: _clear
    };
}(jQuery));
